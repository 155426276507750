.issuerLookup-wrapper {
    .validated-search {
      & .ebocom-warning-message {
        justify-content: center;
        margin: 2rem;
        & span.message-text { 
          font-style: normal!important; 
        }
      }
    }
  }


div.validated-search {
  // margin-bottom: 3rem;
  & .ebocom-warning-message {
    & span.message-text {
      font-style: italic;
      color: #990000;
      font-weight: bold;
      font-size: .75rem;
      margin: 0 1rem;
    }
  }
}

.ebocom-detail-warning-message {
  font-size: 13px;
  font-weight: bold;
  font-style: italic;
}