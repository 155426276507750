$spinner-color: #7d5195;

.spinner {
  border-style: solid;
  border-radius: 50%;
  animation: spin 1.5s infinite;
  position: relative;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
