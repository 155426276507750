$family-primary: Arial, Helvetica, sans-serif;
$table-cell-padding: 1px;
$body-color: #000;
$table-color: #000;
$title-color: #000;
$black: #000;
$black-bis: #000;
$black-ter: #000;
$dark: #000;
$button-text-color: #000;
$body-line-height: 1;
$modal-card-head-radius: 0;
$modal-card-foot-radius: 0;
@import "../node_modules/bulma/bulma.sass";
@import "./styles/checkbox.scss";

*:disabled {
  cursor: default !important;
}

.modal-content {
  border: 2px solid black !important;
}
.has-background-transparent {
  background-color: transparent;
}

.compact-link-menu {
  border-right: 1px solid rgb(204, 204, 204);
  padding: 5px 20px;

  & .column-title {
    color: white;
    font-weight: bolder;
    display: inline-block;
  }
  &:last-of-type {
    border-right: none;
  }
}

.menu-shift-left {
  margin-left: -6px;
  & img {
    margin-right: 6px;
  }
}

.table {
  font-size: 11px;
}
.table {
  tr:has(th) {
    min-height: 41px;
  }
}
body {
  font-size: 11px;
}

button.ie-styled {
  font-size: 11px;
  border: 1px solid darkgray;
  height: 20px;
}

.main-menu {
  margin-left: 45px;
  // margin-left: -10px;
}

.privacyHeader {
  margin: auto;
  text-align: center;
  padding-top: 20px;
}

.privacySubHeader {
  margin-bottom: 0 !important;
}

.privacyLink {
  color: purple;
  text-decoration: underline;
}

.content.privacy li + li {
  margin-top: 0;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.MuiTreeItem-label label {
  width: 100%;
  cursor: pointer;
}

button {
  cursor: default;
}

iframe#webpack-dev-server-client-overlay {
  display: none!important;
}

.input-error {
  border: 1px solid #cc0f35!important;
  border-radius: 2px;
}

.input-error:focus-visible {
  border: 2px solid #cc0f35!important;
  border-radius: 2px;
  outline-width: 0;
}