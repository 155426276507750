@import "../styles/colors.css";


// button.is-text {
//   border: 0;
//   background: none;
// }

// button.is-text:hover {
//   background-color: var(--main-accent-color);
// }

.primary-links a {
  text-decoration: none !important;
}

.inner-frame {
  border: var(--main-bg-color) 2px solid;
}

.inner-children {
  margin: 2px 11px;
}

button.plain-text {
  border: 0;
  background: none;
  text-align: left;
  padding: 0;
  font-size: 10px;
}

.footer {
  background-color: #ffffff!important;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 10px;
  
  & .col-left {
    width: 33%;
    text-align: left;
  }

  & .col-mid {
    width: 34%;
    text-align: center;
  }

  & .col-right {
    width: 33%;
    text-align: right;
  }

  & div {
    margin: 0.06125rem 0;
  }
}

.title-bar {
  margin-bottom: 1rem;
}
