.deposit-detail-table {
 
  th {
    text-transform: uppercase;
  }
  .table {
    & tr {
      &.is-highlighted {
        background-color: yellow;
      }
      height: 16px;
      td {
        &.detail-cell {
          background-color: white !important;
        }
        font-size: 9px;
        vertical-align: middle;
      }
    }
  }
}

.deposit-detail-table {
  .table {
    table-layout: fixed;
  }
}

.chargeback-detail, .transaction-detail, .outlet-detail, .date-detail, .merchant-detail, .all-locations-summary {
  margin-bottom: 2rem;
}