@import "../styles/colors.css";

.table.transaction-search-panel {
  & tr > th {
    background-color: var(--main-bg-color);
    color: white;
    font-size: 11px;
    text-align: center !important;
    height: 41px;
    vertical-align: middle;
    tbody td {
      padding: 0.75rem;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    
  }
  .field {
    height: 100%;
    display: flex;
    margin: 0;
    margin: .25rem .075rem;
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 21px;
    height: 21px;
    input {
      min-height:21px;
      height: 21px;
    }
    select {
      min-height: 21px;
      height: 21px;
      margin: 0 .075rem;
    }
    .merchant-name-dropdown {
      min-height: 21px;
      height: 21px;
      border-radius: 0;
    }
  }
  input,
  select {
    width: "100%";
  }
  select {
    font-size: 11px;
  }
  .card-input-container {
    position: relative;
    &:hover {
      .card-number-tooltip {
        position: absolute;
        bottom: -100px;
        display: block;
        height: 100px;
        width: 300px;
        background-color: red;
      }
    }
    .card-number-tooltip {
      display: none;
    }
  }
}

.table.standard-report-panel {
  & tbody td {
    padding: .5rem 0;
  }
}

.search-button-bar {
  margin: 1px;
  button {
    margin-left: 2px;
  }
}