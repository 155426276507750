.front-page .standard-links {
  margin-right: 1px;
}

.standard-links {
  font-size: 0.625rem;
  font-weight: normal;
  margin-right: 1px !important;

  &.is-light {
    a,
    button {
      color: white;
      &:hover {
        color: white;
      }
    }
  }

  & button.plain-text {
    text-decoration: none;
    background: none;
    border: 0;
  }

  & .is-pointer {
    cursor: pointer!important;
    &:hover {
      text-decoration: underline;
      background-color: inherit;
      color: inherit;
    }
  }
}
