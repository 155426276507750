.issuerLookup {
    margin: auto;
    width: 50%;
  }
  
  .issuerLookup table {
    width: 250px;
    margin: 0 auto;
  }
  
  .issuerLookup table tr > th {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-style: solid;
    border-width: 2px !important;
    border-color: #cccccc;
  }
  
  .issuerLookup table td:first-child {
    text-align: center;
    width: 160px;
  }
  
  .issuerLookupInput {
    width: 85px;
  }
  
  .issuer-lookup-results {
    tr > td {
      height: 16px;
      min-height: 16px;
    }
  }
  