.global-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(60, 60, 60, 0.3);
  z-index: 9999;
}

.global-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}