@import "../styles/colors.css";

  
.main-content {
  /* background-color: var(--main-accent-color); */
  margin: 0px;
  padding: 0px !important;
}

.center {
  margin: auto;
}

.home-menu-column {
  // margin-top: 32px;
  width: 170px;
  white-space: nowrap;
  margin-top: 48px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  &:nth-of-type(3) {
    margin-left: 42px;
  }
  & .link-row {
    margin: 6px 5px;
    text-align: left;
  }
}

.modal-background {
  background-color: rgba(0,0,0,0);
}

.front-page-container {
  & .footer {
    margin-top: 0;
    font-weight: normal;

    & .col-left {
      width: 35%;
      text-align: left;
    }

    & .col-mid {
      width: 35%;
      text-align: center;
    }

    & .col-right {
      width: 30%;
      text-align: right;
    }
    
    & div {
      margin: .06125rem 0;
    }
    
    & select {
      border-radius: 0;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}

select, select option {
  text-transform: uppercase;
}