// .tooltip-container {
//   text-decoration: underline;
//   color: #c546a5;
// }

.purple {
  color: #c546a5;
}

.is-underlined {
  text-decoration: underline;
}

.tooltip-container {
  position: relative;

  & .tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    & .two-column.div:first-child { width: 30%; }
    & .two-column.div:last-child { width: 70%; }
    & .header {
      background-color: var(--main-bg-color);
      font-weight: bold;
    }
  }


  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease-in-out 0.5s;
      z-index: 1000;
    }
  }
}

.tooltip {
  transition: opacity 0.3s ease-in-out;
  background-color: #e5e7ff;
  color: black;
  font-size: 10px;
  .header {
    padding: .125rem;
    background-color: #392852;
    color: white;
    font-size: 13px!important;
  }
  .content {
    padding: .125rem;
  }
}


.transaction-status-tooltip {
  width: 300px;
  .tooltip-line {
    padding: .125rem;
  }
  .left {
    width: 30%;
  }
  .is-bold {
    font-weight: bold;
  }
}
