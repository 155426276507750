.loading-drape {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.management-search {
  select {
    width: 98%;
    font-size: 10px;
    option {
      font-size: 10px;
    }
  }
  tbody tr > td {
    min-height: unset;
    height: unset;
  }
  select {
    height: 21px;
  }
  input {
    height: 21px;
  }
}

.dl-mgmt-report {
  text-decoration: underline;
  color:rebeccapurple;
}
