.document table tr > th {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
}

.document table tr > th:last-child {
    padding-left: 65px;
}

.document table td {
    border-width: 0;
    text-align: left;
    width: 800px;
    vertical-align: middle;
}

.document table td:first-child { 
    text-align: center; 
    width: 25px;
}