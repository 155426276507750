input[type="checkbox"], .MuiCheckbox-colorPrimary.Mui-checked {
  accent-color: #392852!important;
  margin: 0 .23rem;
  color: black;
}

.management-search {
  & .MuiSvgIcon-root {
    fill: #392852!important;
  }
}