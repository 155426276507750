@import "../styles/colors.css";
.dropdown {
  display: flex;
  flex-direction: column;

  .link-row {
    margin: 5px;
  }
  &.compact {
    font-size: 0.9rem;
    font-weight: bold;
    white-space: nowrap;
    & li {
      padding: 0px;
    }
    & li:hover > ul {
      display: block;
      z-index: 10;
      li {
        font-weight: 300;
        padding: 5px 5px;
        border-top: 1px solid white;
        border-right: none;
        border-left: none;
        &:first-of-type {
          border-top: none;
        }
      }
    }
  }

  list-style-type: none;
  margin: 0;
  padding: 0;
  color: black;
  &.inverted {
    color: white;
    & li li {
      background-color: #78698e;
    }
    & * {
      color: white;
    }
  }
  & a {
    color: black;
    text-decoration: none;
  }
  & li {
    padding: 4px;
    position: relative;
  }
  & li:hover > div {
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
  }

  & ul {
    // border: 1px solid rgb(222, 222, 222);
    display: none;
    left: 20%;
    position: absolute;
    top: 100%;
    list-style-type: none;
    background-color: white;
    font-size: 10px;
  }
  & li:hover > ul {
    display: block;
    z-index: 10;
    li {
      padding: 6px 8px;
      border-top: 1px solid black;
      border-left: 1px solid black;
      border-right: 1px solid black;
      &:last-of-type {
        border-bottom: 1px solid black;
      }
    }
  }
  & li:hover {
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  & ul ul {
    left: 100%;
    position: absolute;
    top: 0;
  }
}
