textarea {
  resize: both;
  width: 600px;
  height: 170px;
  margin-left: 10px !important;
}

.title.is-7 {
  margin-bottom: 0 !important;
}
