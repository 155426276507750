@import "../../../styles/tables.scss";
.dispute-summary-table {
  table {
    position: relative;
    th {
      height: 20px;
      padding: 2px 5px !important;
    }
    td {
      height: 26px;
    }
  }
}

// table > tr:first-of-type {
//   position: sticky;
//   background: white;
//   top: 0;
// }
