.table {
  & tr > th {
    background-color: var(--main-bg-color);
    color: white;
    font-size: 11px;
    text-align: center;
    vertical-align: middle!important;
  }
  & .button {
    font-size: 9px;
  }

  width: 100%;
  td input,
  .ebocom-select,
  td select {
    height: 21px;
  }
  td select,
  td option,
  td input,
  .ebocom-select {
    font-size: 11px;
    text-transform: uppercase;
  }
}
tbody td {
  padding: 0.75rem;
  font-size: 9px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
tbody tr > td {
  min-height: 21px;
  height: 26px;
}

.paginated-table-header-row {
  padding-bottom: .125rem;

  .middle {
    align-self: center;
  }

  & img {
    width: 28px;
    height: 28px;
  }
  
}

.table th:not([align]) {
    text-align: center;
}
